<template>
	<div>
		<div class="h-auto filter mb-5">
			<div class="w-full rounded-lg flex flex-wrap items-center justify-between gap-5">
				<h1 class="text-xl h-6 font-extrabold sm:text-2xl">Отзывы пациентов</h1>
				<div class="flex items-center justify-end">
					<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
				</div>
			</div>
		</div>

		<div class="flex flex-col relative">
			<div
				class="t-overflow min-w-full rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default"
				:class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }"
				:style="data.length > 14 ? 'height: calc(100vh - 320px)' : ''"
			>
				<table class="w-full table-fixed" :style="data.length > 0 ? 'min-height: 410px' : ''">
					<colgroup>
						<col style="width: 4.9rem" />
						<col style="min-width: 18.8rem" />
						<col style="width: 16rem" />
					</colgroup>
					<thead>
						<tr :class="{ tr1__sticky: data.length > 14 }">
							<th class="text-center px-6 py-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">П/П</th>
							<th v-for="(item, ind) in fields" :key="'J' + ind" class="px-6 py-4 border-b border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">
								<span class="flex relative">
									<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />
									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:position="ind === 0 ? 'left' : 'right'"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields"
										:defaultSortField="'moName'"
										:item="item"
									/>
								</span>
							</th>
						</tr>
					</thead>
					<tbody v-for="(item, indx) in data" :key="'A' + indx">
						<tr :class="{ 'bg-gray-30': indx === opened }">
							<td class="w-1/12 text-center font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span v-if="params.currentPage === 0">
									{{ indx + 1 }}
								</span>
								<span v-if="params.currentPage > 0">
									{{ params.currentPage * params.pageSize + (indx + 1) }}
								</span>
							</td>
							<td
								v-for="(field, ind) in fields"
								:key="'L' + ind"
								class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500 text-left"
								:class="{ 'border-none': item.id === opened }"
							>
								<span v-if="field.fieldName === 'countByMO'" @click="openRow(indx)" class="cursor-pointer text-blue-500 font-semibold underline">
									{{ item.feedBackList.length }}
								</span>
								<span v-else>
									{{ item[field.fieldName] }}
								</span>
							</td>
						</tr>
						<tr v-if="opened === indx">
							<td colspan="3" class="bg-gray-20">
								<table class="w-full">
									<thead>
										<tr>
											<th
												v-for="(subfield, ind) in subfields"
												:key="'M' + ind"
												class="px-6 py-3 border-b border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
												:class="subfield.fieldName === 'feedBackDate' ? 'w-64' : 'w-auto'"
											>
												<span>
													{{ subfield.Name }}
												</span>
											</th>
										</tr>
									</thead>
									<tbody v-for="(subitem, indx) in item.feedBackList" :key="'N' + indx">
										<tr>
											<td
												v-for="(subfield, ind) in subfields"
												:key="'K' + ind"
												class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500 text-left"
											>
												<span v-if="subfield.fieldName === 'feedBackDate'">
													{{ subitem[subfield.fieldName] | moment('DD.MM.YYYY') }}
												</span>
												<span v-else>{{ subitem[subfield.fieldName] }}</span>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<Pagination :rowsCount="totalRows" :pageSize="params.pageSize" :pageNumber="params.currentPage" :pagesCount="totalCount" @query="updatePage" />
			</div>
			<!-- Прелоудер -->
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
	</div>
</template>

<script>
import { getters, mutations } from '@/store/store';
// Локальные данные стора
import actions from '../store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import Button from '@/components/Button.vue';
import FilterComponent from '@/components/Filter.vue';
import Icons from '@/components/Icons.vue';

export default {
	name: 'TableReviews',
	components: {
		loadingComponent,
		Pagination,
		Button,
		Icons,
		FilterComponent
	},
	computed: {
		...getters
	},
	data() {
		return {
			loadingTable: false,
			loadToExcell: false,
			totalRows: 0,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'moName',
					filterType: 2,
					filterValueType: 1,
					autocomplete: true,
					list: [],
					widthStroke: 'w-244',
					heightUl: '150px',
					itemKey: 'shortName',
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Количество отзывов',
					fieldName: 'countByMO',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			subfields: [
				{
					Name: 'Дата заполнения',
					fieldName: 'feedBackDate'
				},
				{
					Name: 'Отзыв',
					fieldName: 'feedBack'
				}
			],
			data: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					}
				],
				sortOption: {
					fieldName: 'moName',
					sortType: 2
				},
				pageSize: this.$DefaultPageSize,
				currentPage: 0,
				columns: []
			}
		};
	},
	methods: {
		...mutations,
		...actions,
		openRow(id) {
			if (this.opened === id) {
				this.opened = null;
			} else {
				this.opened = id;
			}
		},
		calcDoneMOPercent(checkedMO, setMO) {
			return Math.floor((checkedMO / setMO) * 100) || '0';
		},
		calcProgressBarWidth(percent) {
			return `width: ${percent}%`;
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updatePage(input) {
			this.params.pageSize = input.pageSize;
			this.params.currentPage = input.pageNumber;
			this.getData(this.params, false);
		},
		getData(params, flag) {
			this.loadingTable = true;

			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
			}

			this.getFeedbacks(params).then((res) => {
				this.data = res.data.data;
				this.totalRows = res.data.totalRecords;
				this.totalCount = params.pageSize ? Math.ceil(res.data.totalRecords / params.pageSize) : 1;
				this.loadingTable = false;
			});
		},
		showMessages(text, type) {
			this.$toast[type](text, {
				position: 'top-right',
				timeout: 5000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post('Anketa/GetFeedbacksExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_Отзывы.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.showMessages('Ошибка выгрузки, обратитесь к Администратору!', 'error');
					}
				});
		}
	},
	mounted() {
		this.params.filters[0].value1 = this.region.id;
		this.getData(this.params, false);
		this.getAllMedicalOrgsForRegions(this.region.id).then((res) => {
			this.fields[0].list = res.data.data;
		});
	}
};
</script>

<style scoped>
.tr1__sticky {
	background-color: #f9fafb;
	top: 0;
	position: sticky;
	z-index: 7;
}
.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}
</style>
